// Select the map element and get data attributes
const mapElement = document.getElementById("np-map");
const latitude = parseFloat(mapElement.getAttribute("data-lat"));
const longitude = parseFloat(mapElement.getAttribute("data-lng"));

// Initialize the Mapbox map
mapboxgl.accessToken =
  "pk.eyJ1IjoiaGFtaWx0b25icm9va2UiLCJhIjoiY20zMnYzYmZzMWgxZjJuc2N0Z2tyeHg0cCJ9.rlpCwmemOYCf40yY2jwI3w";
const map = new mapboxgl.Map({
  container: "np-map",
  style: "mapbox://styles/mapbox/streets-v11",
  center: [longitude, latitude],
  zoom: 15,
});

// Create a custom SVG element
const svgMarker = document.createElement("div");
svgMarker.innerHTML = `
  <svg width="42" height="64" viewBox="0 0 42 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.33,64c1.28-21.3,19.67-26.7,19.67-42.7,0-5.9-2.36-11.2-6.2-15.1-3.74-3.8-8.95-6.2-14.75-6.2S10.03,2.4,6.2,6.2C2.36,10.1,0,15.4,0,21.3c0,16,18.39,21.3,19.67,42.7h2.66Z" fill="#ec1c24"/><polygon points="37 23.53 32.31 18.83 32.31 21.66 25.51 21.66 30.31 16.82 32.31 18.83 32.31 12.2 25.69 12.2 27.69 14.21 22.86 19.05 22.86 12.2 25.69 12.2 21 7.5 16.31 12.2 19.14 12.2 19.14 19.05 14.31 14.21 16.31 12.2 9.69 12.2 9.69 18.83 11.69 16.82 16.49 21.66 9.69 21.66 9.69 18.83 5 23.53 9.69 28.23 9.69 25.36 16.49 25.36 16.49 34.5 25.51 34.5 25.51 25.36 32.31 25.36 32.31 28.23 37 23.53" fill="#fff"/>
  </svg>
`;
svgMarker.style.width = "42px";
svgMarker.style.height = "64px";

// Add the SVG marker to the map with bottom alignment
new mapboxgl.Marker(svgMarker, { anchor: "bottom" })
  .setLngLat([longitude, latitude])
  .addTo(map);
